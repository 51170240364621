import React, { Suspense } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import PrivateRoute from './components/PrivateRoute';
import { AuthContextProvider } from './authContext';


const LoginPage = React.lazy(() => import('./pages/Login/Login'));
const DashboardPage = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const HomePage = React.lazy(() => import('./pages/Home/Home'));
const PublicImagePage = React.lazy(() => import('./pages/PublicImage/PublicImage'));


function App() {

  const history = createBrowserHistory();

  return (
    <AuthContextProvider>
      <Router history={history}>
          <Switch>
              <Suspense fallback={<CircularProgress />}>
                  <Route path="/" component={HomePage} exact />
                  <Route path="/login" component={LoginPage} exact />
                  <Route path="/qr/:event/:guid" component={PublicImagePage} exact />
                  <PrivateRoute path="/dashboard" exact><DashboardPage /></PrivateRoute>
              </Suspense>
          </Switch>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
