import { CircularProgress } from "@mui/material";
import { Redirect, Route } from "react-router";
import { useAuthState } from "../authContext";


function PrivateRoute({ children, ...props }) {
  const { isAuthenticated, isInitialised } = useAuthState();

  if(isInitialised) {
    return (
      <Route
        {...props}
        render={({ location }) => 
          isAuthenticated ? children : <Redirect to={{ pathname: "/login", state: { from: location } }} />
        }
      />
    );
  }

  return <CircularProgress />

}

export default PrivateRoute;
