import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { useState, useEffect, createContext, useContext } from "react";
import firebaseApp from "./firebase";

export const AuthContext = createContext({user: null, isInitialised: false});

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(firebaseApp), setUser, setError);
    return () => unsubscribe();
  }, []);

  return <AuthContext.Provider value={{ user, error, isInitialised: user !== undefined}}>{children}</AuthContext.Provider>;
};



export const useAuthState = () => {
  const auth = useContext(AuthContext);
  return { ...auth, isAuthenticated: auth && auth.user != null };
};
