import { initializeApp, getApps, getApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCaeqg88TpwtFwDC5iw4LlSvnIzPswhLME",
  authDomain: "flavorte-instabooth.firebaseapp.com",
  projectId: "flavorte-instabooth",
  storageBucket: "flavorte-instabooth.appspot.com",
  messagingSenderId: "312436580368",
  appId: "1:312436580368:web:a7cba6e08c94d4542e6a95"
};


const firebaseApp = getApps().length ? getApp() : initializeApp(firebaseConfig);

export default firebaseApp;